<template>
	<div style="width:100%;">
		<mobile-header active="/mobile/tender"></mobile-header>
		<van-search v-model="formSearch.name" show-action label="" placeholder="请输入搜索公告关键词" @search="getTableDate(1)">
			<template #action>
				<van-button type="info" size="small" @click="getTableDate(1)" style="vertical-align: middle;">搜索
				</van-button>
			</template>
		</van-search>
		
		<van-tabs v-model="formSearch.notice" @click="getTableDate('')">
			<van-tab  title="全部" name="">
			</van-tab>
			<van-tab v-for="(item,index) in notice" :title="item.name" :name="item.id" :key="index">
			</van-tab>
			<van-tab  title="" name="">
			</van-tab>
		</van-tabs>

		<!--
		<p style="font-size: 13px;text-align: center;">
			<span style="cursor: pointer;padding:3px 3px;" @click="noticeChange('')"
				:style="[{color:(formSearch.notice==''?'#fff':'#606266')},{backgroundColor:(formSearch.notice==''?'#1989fa':'#fff')}]"
				class="hoverSpan">全部</span>
			<span v-for="(item,index) in notice" style="cursor: pointer;padding:2px 3px;" :key="index"
				@click="noticeChange(item.id)"
				:style="[{color:(formSearch.notice==item.id?'#fff':'#606266')},{backgroundColor:(formSearch.notice==item.id?'#1989fa':'#fff')}]"
				class="hoverSpan">
				{{item.name}}
			</span>
		</p>
		-->
		<div style="font-size: 14px;">
			<table style="text-align: center;border-collapse: collapse;margin:10px 0;width: 100%;" cellpadding="10">
				<thead>
					<tr>
						<th>项目名称</th>
						<th style="width: 33%;">招标方式</th>
						<th style="width: 33%;">报名截止时间</th>
					</tr>
					<tr v-for="(item,index) in tableData" :key="index">
						<td style="color: #0080FF;text-align: left;" @click="goTo(item['tender_info_id'])">
							<div class="van-multi-ellipsis--l3">
								{{item['name']}}
							</div>
						</td>
						<td>{{way[item['way']-1].name}}</td>
						<td>
							<span v-if="item['notice']==1 || item['notice']==2">{{item['end_time']}}</span>
						</td>
					</tr>
					<tr v-if="tableData.length==0">
						<td colspan="3">无数据</td>
					</tr>
				</thead>
			</table>
			<van-pagination v-model="tablePageInfo.currentPage" :items-per-page="tablePageInfo.pageSize"
				:total-items="tablePageInfo.total" @change="getTableDate('')" />
		</div>

	</div>
</template>

<script>
	import Vue from 'vue';
	import {
		Pagination,
		Tab,
		Tabs,
		Search,
		Button
	} from 'vant';
	Vue.use(Pagination);
	Vue.use(Tab);
	Vue.use(Tabs);
	Vue.use(Search);
	Vue.use(Button);
	import mobileHeader from "@components/mobileHeader";
	export default {
		name: 'tender',
		components: {
			mobileHeader,
		},
		data() {
			return {
				nowNotice: '',
				formSearch: {
					state: 1,
					notice: '',
					type: '',
					page: 1,
					limit: 10,
					name: '',
				},
				tableData: [],
				tablePageInfo: {
					pageSize: 10, //每页的条数
					total: 0, //总条数
					currentPage: 1, //当前页
				},
			}
		},
		beforeMount: function() {
			if ('val' in this.$route.query) {
				//搜索进入
				this.formSearch.name = this.$route.query.val;
			}
			this.getTableDate('');

		},
		mounted() {
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/招标信息Mobile.jpg'),
				href: ""
			}]);
		},
		methods: {
			getTableDate: function(res) {
				console.log(res);
				var that = this;
				if(this.tablePageInfo.currentPage >2){
					alert('请登录后在进行查阅！');
					return false;
				}else{
					that.formSearch.page = this.tablePageInfo.currentPage;
				}
				if (res != '') {
					that.formSearch.page = res;
				} //点击查询，恢复第一页
				
				this.$axios.post(this.baseUrl + 'admin/tender/searchTender', that.formSearch).then(response => {
					if (response.data.code != '200') {
						that.$alert(response.data.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						that.tablePageInfo.total = response.data.data.count;
						that.tableData = response.data.data.res
					}
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			goTo(val) {
				this.$router.push({
					path: '/mobile/tenderContent',
					query: {
						id: window.btoa(window.btoa(val))
					}
				})
			},
			noticeChange(val) {
				this.formSearch.notice = val;
				this.getTableDate('');
			},
		}
	}
</script>

<style scoped>
	table th {
		background-color: #F2F6FC;
	}

	table td,
	table th {
		border: #E4E7ED 1px solid
	}
</style>
